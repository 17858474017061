import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth0/auth0-angular';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { AppBarComponent } from '@core/components/app-bar/app-bar.component';
import { PrimaryNavComponent } from '@core/components/primary-nav/primary-nav.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { filter, first } from 'rxjs/operators';
import { switchMap } from 'rxjs';
import { ApiHttpService } from '@core/services/api-http.service';
import { DetailActionService } from '@core/services/detail-action.service';
import { ApplicationUserService } from '@core/services/application-user.service';
import { NavigationService } from '@core/services/NavigationService';
import { MercureService } from '@core/services/mercure.service';

@Component({
  selector: 'rc-app',
  standalone: true,
  imports: [RouterOutlet, AppBarComponent, PrimaryNavComponent, DialogModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  #translateService = inject(TranslateService);
  #authService = inject(AuthService);
  #apiService = inject(ApiHttpService);
  #detailActionService = inject(DetailActionService);
  #applicationUserService = inject(ApplicationUserService);
  #navigationService = inject(NavigationService);
  #mercureService = inject(MercureService);
  constructor() {
    this.#translateService.setDefaultLang('nl');
    this.redirectIfNotLoggedIn();
    this.#applicationUserService.setApplicationUser();
    this.#authService.idTokenClaims$
      .pipe(
        filter((token) => !!token),
        first(),
        switchMap(() => this.#apiService.get('/cookie')),
      )
      .subscribe();
    this.#navigationService.updateDetailsActionEmitter.subscribe((value) => {
      if (value) {
        this.#mercureService.destroyEventEmitter.emit(true);
        this.#detailActionService.setCreationSubject(undefined);
        this.#detailActionService.setActionMenuItemsSubject(undefined);
      }
    });
  }

  redirectIfNotLoggedIn(): void {
    this.#authService.isAuthenticated$.pipe(takeUntilDestroyed()).subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        this.#authService.loginWithRedirect({ authorizationParams: { redirectUrl: '/dashboard', prompt: 'login' } });
      }
    });
  }
}
