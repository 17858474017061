import { Component, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { AsyncPipe } from '@angular/common';
import { UserNotificationsComponent } from '@core/components/user-notifications/user-notifications.component';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { CapitalizePipe } from '@core/pipes/capitalize.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { UserMenuItem } from '@core/models/UserMenuItem';

@Component({
  selector: 'rc-user-menu',
  standalone: true,
  templateUrl: './user-menu.component.html',
  styleUrl: './user-menu.component.scss',
  imports: [AsyncPipe, UserNotificationsComponent, CapitalizePipe, TranslateModule],
})
export class UserMenuComponent {
  authService = inject(AuthService);
  #router = inject(Router);
  menuItems: UserMenuItem[] = [
    {
      text: 'LOGOUT',
      clickAction: () => this.logout(),
      icon: 'rc-icon-disable',
    },
    {
      text: 'SETTINGS',
      clickAction: () => this.navigateToSettings(),
      icon: 'rc-icon-settings',
    },
  ];

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.authService.logout({
      logoutParams: {
        federated: true,
        clientId: environment.auth0.clientId,
        returnTo: window.location.origin,
      },
    });
  }

  navigateToSettings() {
    this.#router.navigate(['/users/settings']);
  }
}
